@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&display=swap');


@import 'variables.scss';

$primary: $primaryColor;
$font-family-sans-serif: Montserrat;

$component-secondary-color: #000;

$input-btn-focus-color: rgba($component-secondary-color, .25);
$input-focus-border-color: tint-color($component-secondary-color, 50%) !default;

$pagination-color: $component-secondary-color;
$pagination-border-color: #dee2e6;

// $pagination-focus-box-shadow: 0 0 0 .25rem rgba($component-secondary-color, .25);

$pagination-focus-color: $component-secondary-color;
$pagination-hover-color: $component-secondary-color;

$pagination-active-bg: $component-secondary-color;
$pagination-active-border-color: $component-secondary-color;

@import '~bootstrap/scss/bootstrap';

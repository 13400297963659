@import 'Sidebar.module.scss';

.providerLabel {
  padding-left: $padding-h;
  margin-bottom: -20px;
  display: block;
  color: #f8f9fa;
  opacity: 0.8;
  font-size: 12.8px;
  line-height: 19.2px;
}

.providerMenuItem {
  padding-top: 26px;
}

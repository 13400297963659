@import '../../styles/variables.scss';
@import '~bootstrap/scss/bootstrap-grid.scss';

.tabs {
  background-color: #fff;
  width: 100%;
  margin-top: 30px;
  display: grid !important;
  grid-template-columns: 1fr 1fr;
  @include media-breakpoint-up(md) {
    width: fit-content;
    display: flex !important;
  }
}

.withoutMarginTop {
  margin-top: 0px !important;
}

.moreThanTwoTabs {
  grid-template-columns: 1fr 1fr 1fr;
}

.tab {
  color: #111 !important;
  border: none !important;
  width: 100%;
  padding: 8px 8px !important;
  @include media-breakpoint-up(md) {
    width: fit-content;
    padding: 8px 16px !important;
  }
  text-align: center;
}

.active {
  color: $primaryColor !important;
  border-bottom: 1px solid $primaryColor !important;
}

.tabTitle {
  position: relative;
}

.badge {
  background: $primaryColor;
  position: absolute;
  top: -10px;
  right: -15px;
}

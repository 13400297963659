.cardText {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.deleteButton {
  margin-left: 20px;
}

.actionButtons {
  text-decoration: none !important;
  padding: 0px !important;
}

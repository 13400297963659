.card {
  border: 0;
  padding: 10px;
}

.redCircle {
  width: 12px;
  height: 12px;
  border-radius: 6px;
  background-color: rgba(220, 53, 69, 255);
}

.cardWithLink {
  color: inherit;
  text-decoration: inherit;
  &:hover {
    color: inherit;
    text-decoration: inherit;
  }
}

@import '~bootstrap/scss/bootstrap-grid.scss';

.page {
  position: relative;
  min-height: 100vh;
  padding-top: 70px;
  padding-bottom: 70px;
  @include media-breakpoint-up(md) {
    padding-top: 70px;
  }
  // margin: 0 50px;
}

.spacer {
  padding-top: 10px;
}

@import '~bootstrap/scss/bootstrap-grid.scss';

.backdrop {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
  background-color: rgba(0, 0, 0, 0.21);
  @include media-breakpoint-up(md) {
    display: none;
  }
}

.backdropEnter {
  opacity: 0;
}

.backdropEnterActive {
  opacity: 1;
  transition: opacity 0.3s;
}

.backdropExit {
  opacity: 1;
}

.backdropExitActive {
  opacity: 0;
  transition: opacity 0.3s;
}

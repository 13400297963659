.searchContainer {
  position: relative;
}

.searchButton {
  position: absolute;
  right: 0;
  top: 0;
  border: none !important;
  &:hover, &:focus, &:active {
    background: transparent !important;
    box-shadow: none !important;
  }
  &:hover, &:active {
    color: #000 !important;
  }
  
}

.searchInput {
  padding-right: 35px;
}
.row {
  margin-top: 5px;
}
.speaker1 {
  text-align: right;
}
.msg {
  display: inline-block !important;
  border-radius: 10px !important;
  width: fit-content;
  font-size: 0.8em;
  max-width: 75%;
}

.row.speaker1 + .row.speaker1,
.row.speaker2 + .row.speaker2 {
  margin-top: 1px;
}

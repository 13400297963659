.container {
}

.header {
  padding: 1rem;
  border-top: solid 1px #ececec;
  border-bottom: solid 1px #ececec;
}

.headerTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.transcriptToggle {
  margin-right: -2px;
  margin-top: -8px;
  padding: 2px;
  text-decoration: none;
  svg {
    vertical-align: text-bottom;
  }
}
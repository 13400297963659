.editIcon {
  margin-left: 8px;
  path {
    fill: #a90000;
  }
}

.cardBody {
  margin-top: 5px;
  strong {
    margin-left: 8px;
  }
}

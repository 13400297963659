@import '~bootstrap/scss/bootstrap-grid.scss';

.tableContainer {
  position: relative;

  font-size: 0.8rem;
  @include media-breakpoint-up(sm) {
    font-size: 0.9rem;
  }
}

.table {
  &:global(.table-hover):not(.noData) {
    tbody {
      tr {
        cursor: pointer;
      }
    }
  }
}
.thead {
  th {
    font-weight: normal;
  }
}

.loadingIndicator {
  position: absolute;
  // right: 10px;
  // top: 0;
  left: 0;
  margin-right: -1px;
  margin-left: -1px;
  bottom: 0;
  width: 100%;
  // height: 100%;
  display: flex;
  align-items: center;
}

@import '../../styles/variables.scss';
@import '~bootstrap/scss/bootstrap-grid.scss';

$base-font-size: 14px;
$sidebar-width: $base-font-size * 15;
$padding-h: 12px;
$menu-item-height: $base-font-size * 3;
$bottom-margin: $base-font-size * 1.375;

.sidebarPlaceholder {
  width: $sidebar-width;
}

.sidebarFixed {
  width: $sidebar-width;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  position: fixed;
  background-image: url('../../assets/images/sidebar-bg.png');
  background-color: #1a1a1a;
  background-size: cover;
  background-position: top left;
  max-height: 100vh;
  height: 100vh;
  z-index: 4;
  // max-height: 100vh;
  overflow-y: auto;
  transition-duration: 0.3s;
  transform: translateX(0);

  .closeButton {
    // margin-top: 20px;
    // margin-left: 180px;
    font-size: 18px;
    z-index: 2;
    position: absolute;
    // padding: 0;
    // border: 0;
    left: 13px;
    top: 8px;
    // border: 1px solid #fff !important;
    // margin-top: 20px;
    // margin-left: 14px;
  }
  
  &.hide {
    transform: translateX(-210px);
    @include media-breakpoint-up(md) {
      transform: translateX(0);
    }
  }
}

.sidebar {
  font-size: $base-font-size;
  padding-bottom: $menu-item-height + $bottom-margin;
  position: relative;
  height: 100%;

  .sidebarLogo {
    width: $sidebar-width;
    max-width: 10em;
    margin-top: 3.75em;
    margin-bottom: 3.75em;
  }

  .providerSelectContainer {
    margin-top: 52px;
  }

  .userDropdownContainer {
    bottom: $bottom-margin;
    position: absolute;
    width: 100%;
  }
}


.container {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.logo {
  width: 100%;
  max-width: 200px;
  margin: 20px;

  animation: pulse 1.5s infinite linear;
}

@keyframes pulse {
  0% {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  50% {
    opacity: 0.7;
    -webkit-transform: scale(0.98);
            transform: scale(0.98);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

.inputDateWrapper {
  display: grid;
  grid-template-columns: 9fr 1fr;
}

.calendarIcon {
  path {
    fill: #6c757d;
  }
  width: 30px;
  height: 36px;
}

.dateInput {
  border: 1px solid #ced4da;
  border-radius: 4px 0px 0px 4px;
  height: 38px;
  border-right: 0px;
  padding: 0.375rem 0.75rem;
  &:focus {
    outline: none;
  }
}

.iconInputButton {
  width: 100%;
  border: 1px solid #ced4da;
  color: #ffffff;
  border-left: 0px;
  border-radius: 0px 4px 4px 0px;
}

.playOrPauseButton {
  padding: 0px !important;
  // padding: 10px !important;
  height: 48px;
  width: 48px;
  border-radius: 50% !important;
  display: inline-block;
  flex: 1 0 48px;
}

.playerWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.playerControls {
  flex: 1 1 auto;
  width: 100%;
  // margin-left: 8px;
  padding-left: 1em;
  font-size: 0.8em;
  .controlsHeader {
    display: flex;
    justify-content: space-between;
    height: 2em;
    align-items: flex-end;
  }
  .controlsFooter {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    // margin-top: 0.1em;
    height: 2em;
  }
  .progress {
    height: 4px;
    border-radius: 2px;
    line-height: 1em;
    $track-height: 4px;
    margin-bottom: 12px;
    $thumb-size: $track-height * 3;
    &::-webkit-slider-runnable-track {
      height: $track-height;
      background-color: #11ffee00;
    }
    &::-moz-range-track {
      height: $track-height;
    }
    &::-webkit-slider-thumb {
      height: $thumb-size;
      width: $thumb-size;
      border-radius: $thumb-size/2;
    }
    &::-moz-range-thumb {
      height: $thumb-size;
      width: $thumb-size;
      border-radius: $thumb-size/2;
    }
  }

  .playbackRateButton {
    padding: 0 4px;
    font-size: 0.8em;
  }
  // .remainingTime {
  //   padding-top: 3px;
  //   margin-top: -10px;
  //   display: flex;
  //   flex-direction: row;
  //   justify-content: flex-end;
  //   button {
  //     padding: 1px 2px 1px 2px;
  //   }
  //   div {
  //     width: 40px;
  //   }
  // }
}

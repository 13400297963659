@import 'Sidebar.module.scss';

.dropdownMenu {
  font-size: $base-font-size;
  width: calc(100% - #{$padding-h} * 2);
  margin-left: $padding-h !important;
  margin-bottom: -5px !important;

  :global(.text-primary) {
    &:active, &:focus {
      color: white !important;
    }
  }
}

@import '~bootstrap/scss/bootstrap-grid.scss';

.navbarForMobile {
  position: fixed !important;
  z-index: 2;
  width: 100%;
  background-color: #000000;
  .menuButton {
    // padding: 0;
    // margin-left: 5px;
    font-size: 18px;
  }
  .logo {
    margin-right: calc(50% - 60px);
    img {
      height: 26px;
    }
  }
}

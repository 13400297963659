.statCard{
    height: 100%;
    .body{
        padding-top: 6px;
        padding-bottom: 4px;
        span{
            font-size: 0.8em;
        }
        .value{
            margin-bottom: 2px;
        }
    }

}
@import '~bootstrap/scss/bootstrap-grid.scss';

.contentContainer {
  padding-right: 0 !important;
  flex: 1 0;
  max-width: 100vw;
  @include media-breakpoint-up(md) {
    padding-right: calc(var(--bs-gutter-x) * 0.5) !important;
  }
}

.sidebarContainer {
  width: 0;
  @include media-breakpoint-up(md) {
    flex: 0 0 auto;
    width: auto;
  }
}

@import 'Sidebar.module.scss';

.sidebarMenuItem {
  // display: block;
  height: $menu-item-height;
  text-decoration: none;
  padding: $padding-h;
  display: flex;
  width: 100%;
  align-items: center;
  color: #fff;
  transition-duration: 0.3s;

  &.clickable {
    cursor: pointer;
  }
  &[href]:hover, &.clickable:hover {
    color: #ffffff;
    background-color: scale-color(#ffffff, $alpha: -90%);
  }

  .sidebarIconContainer {
    text-align: center;
    padding-right: 6px;
  }
  .sidebarRightIconContainer {
    text-align: center;
    margin-left: auto;
  }
  .sidebarItemTitle {
    margin-top: 3px;
  }
}

.sidebarMenuItemActive {
  background-color: scale-color(#ffffff, $alpha: -80%);
  &[href]:hover {
    background-color: scale-color(#ffffff, $alpha: -80%);
  }
  position: relative;
  &::before {
    display: block;
    content: ' ';
    position: absolute;
    left: 0;
    top: 0;
    width: 3px;
    height: 100%;
    background-color: scale-color($primaryColor, $alpha: -50%);
  }
}

.sidebarMenuItem:global(.dropdown-toggle) {
  &::after {
    display: none;
  }
}
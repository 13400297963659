.title {
  margin-top: auto !important;
  margin-bottom: auto !important;
  h5 {
    margin-bottom: 0px;
  }
}

.convertIntoNoteTemplateButton {
  svg {
    margin-bottom: 3px;
  }
  &:hover {
    path {
      fill: #ffffff;
    }
  }
}

.breadcrumb {
  background-color: #e9ecef;
  padding: 12px 16px;
  width: fit-content;
  a {
    text-decoration: none;
  }
  ol {
    margin-bottom: 0px;
  }
}
